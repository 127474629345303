<template>
  <div class="px-2 pt-6 ma-2 ma-md-6 d-flex align-center flex-column">
    <PaymentHeader :is-yearly="isYearly" />

    <Payment
      v-if="originalCustomer"
      ref="payment"
      :product-type="productType"
      :is-yearly="isYearly"
      :currency="currency"
      :customer-default="originalCustomer"
      @cart="cart = $event"
      @customer="customer = $event"
    />

    <v-row class="my-2 w-full d-flex flex-row gap-1 justify-center">
      <v-btn
        outlined
        :color="$vuetify.theme.themes.light.markero.blue"
        class="py-6 px-md-16 flex-md-grow-0 flex-grow-1"
        :disabled="isLoading"
        @click="$emit('back')"
      >
        {{ $t('labels.back') }}
      </v-btn>
      <v-btn
        elevation="20"
        :color="$vuetify.theme.themes.light.markero.blue"
        class="white--text py-6 px-md-16 flex-md-grow-0 flex-grow-1"
        :loading="isLoading"
        @click="submit"
      >
        {{ $t('labels.proceed') }}
      </v-btn>
    </v-row>

    <v-row class="ma-0 ma-md-5">
      <v-col
        class="px-0"
        md="8"
        offset-md="2"
      >
        <PaymentProviderInfo />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue'
import GET_ONBOARDING_BILLING_DATA from '../billwerk/subscribe/queries/getOnboardingBillingData.gql'
import SELF_SERVICE_PORTAL_URL from '@/modules/settings/accountSettings/queries/SelfServicePortalUrl.gql'
import CHANGE_PLAN from './queries/changePlan.gql'

import PaymentProviderInfo from './PaymentProviderInfo.vue'
import Payment from './Payment.vue'
import PaymentHeader from './PaymentHeader.vue'
import { billwerkApi } from '../billwerk/lib'
import { showSnackbarMessage } from '@/lib/snackbarMessages'

export default {
  components: { PaymentProviderInfo, Payment, PaymentHeader },
  props: {
    productType: {
      type: String,
      required: true
    },
    isYearly: {
      type: Boolean,
      default: true
    },
    currency: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      couponCode: '',
      isLoading: false,
      cart: {},
      customer: {},
      originalCustomer: null,
      onboardingBillingData: { address: {} }
    }
  },

  watch: {
    async token (token) {
      if (token) {
        const { Customer: customer } = await billwerkApi.getContractDetails(token)
        const address = customer.Address || {}
        this.originalCustomer = {
          email: customer.EmailAddress || this.onboardingBillingData.emailAddress,
          companyName: customer.CompanyName || this.onboardingBillingData.companyName,
          firstName: customer.FirstName || this.onboardingBillingData.firstName,
          lastName: customer.LastName || this.onboardingBillingData.lastName,
          vatId: customer.VatId || this.onboardingBillingData.vatId,
          address: {
            addressLine1: address.AddressLine1,
            street: address.Street || this.onboardingBillingData.address.street,
            houseNumber: address.HouseNumber || this.onboardingBillingData.address.houseNumber,
            postalCode: address.PostalCode || this.onboardingBillingData.address.postalCode,
            city: address.City || this.onboardingBillingData.address.city,
            country: address.Country || this.onboardingBillingData.address.country
          }
        }
      }
    }
  },
  mounted () {
    this.$tracking.event('Add Payment Info', 'Clicked')
  },
  methods: {
    async submit () {
      if (!this.$refs.payment.validate()) {
        return
      }
      this.isLoading = true
      try {
        await billwerkApi.customerChange({ token: this.token, customer: this.customer })
        const { data: { order } } = await this.$apollo.mutate({
          mutation: CHANGE_PLAN,
          variables: { input: this.cart }
        })
        if (await this.needsInteractivePayment()) {
          await this.payInteractive(order)
        } else {
          await this.navigateToPaymentFinalize()
        }
      } catch (error) {
        showSnackbarMessage('error', this.$t('alerts.settings.plan-change.error'))
        Sentry.captureException(error)
      } finally {
        this.isLoading = false
      }
    },

    async needsInteractivePayment  () {
      const details = await billwerkApi.getContractDetails(this.token)
      return !details.Contract.PaymentProvider
    },

    async payInteractive  (order) {
      const { Url } = await billwerkApi.upgradePayInteractive({
        order,
        token: this.token,
        paymentMethod: this.customer.PaymentMethod,
        providerReturnUrl: `${window.location.origin}/payment-finalize/upgrade?productType=${this.productType}`
      })
      if (Url) {
        // if a URL is returned, we redirect the user to the payment provider (credit card, PayPal, etc.)
        window.location.href = Url
        // wait for the redirect to finish
        await new Promise(resolve => setTimeout(resolve, 5000))
      } else {
        // if no URL is returned, we can directly finalize the payment
        await this.navigateToPaymentFinalize()
      }
    },

    async navigateToPaymentFinalize  () {
      await this.$router.push({ name: 'payment-finalize', params: { action: 'upgrade' }, query: { finalized: 'true' } })
    }
  },

  apollo: {
    onboardingBillingData: { query: GET_ONBOARDING_BILLING_DATA, update: (data) => data.onboardingBillingData.customer },
    token: { query: SELF_SERVICE_PORTAL_URL, update: (data) => data.billingSelfServicePortalDetails?.Token }
  }
}
</script>

<style scoped>
@media (max-width: 600px) {
  .benefits {
    justify-content: space-evenly;
  }
}
</style>
